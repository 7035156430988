import React, { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

import { ErrorBoundary, FullPageSpinner, ThemeProvider, ToastRenderer } from "@resamare/ui";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthLoader } from "@/lib/auth";
import { PermissionsProvider } from "@/lib/permissions";
import { queryClient } from "@/lib/react-query";
import { theme } from "@/config/theme";

export function AppProvider({ children }: PropsWithChildren) {
    return (
        <React.Suspense fallback={<FullPageSpinner />}>
            <ThemeProvider theme={theme}>
                <ErrorBoundary>
                    <QueryClientProvider client={queryClient}>
                        <ReactQueryDevtools buttonPosition="bottom-right" />
                        <PermissionsProvider>
                            <BrowserRouter>
                                <AuthLoader renderLoading={() => <FullPageSpinner />}>
                                    {children}
                                </AuthLoader>
                            </BrowserRouter>
                        </PermissionsProvider>
                    </QueryClientProvider>
                </ErrorBoundary>
                <ToastRenderer />
            </ThemeProvider>
        </React.Suspense>
    );
}
